import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Select, Input, Button, Spin, notification } from 'antd';
import { getLocation, getrestaurantByLocation, getApitoken} from './module/landingScreen/index';
import { verifypin } from './module/kichtenCDS/kitchencds';
import './App.css'

const { Option } = Select;

const App = ({ setIsAuthenticated }) => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pin, setPin] = useState('');
  const [outlets, setOutlets] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState('');
  const [apiToken, setApiToken] = useState(null);
  const navigate = useNavigate();

  const fetchAPIToken = async () => {
    try {
      const res = await getApitoken();
      if (res.status === 200) {
        setApiToken(res.data.message.data);
      } else {
        console.error("Failed to fetch API token:", res.status);
      }
    } catch (error) {
      console.error("Error fetching API token:", error);
    }
  };

  useEffect(() => {
    fetchAPIToken();
  }, []);

  const handleLocationChange = (value) => {
    setSelectedLocation(value);
    setSelectedStore(null);
  };

  const handleStoreChange = (value) => {
    const outlet = outlets.find(outlet => outlet.cost_center_name === value);
    setSelectedStore(outlet);
  };

  const handleRouteChange = (value) => {
    setSelectedRoute(value);
  };

  const handlePinChange = (e) => {
    setPin(e.target.value);
  };

  const handleSubmit = async () => {
    if (selectedStore && pin && selectedRoute) {
      localStorage.setItem("kdscdsCostCenter", selectedStore.name)
      try {
        const res = await verifypin(pin, selectedStore.name, apiToken);
        if (res.data.message.is_verified === true) {
          setIsAuthenticated(true);
          navigate(selectedRoute);
        } else {
          notification.error({ message: "Invalid pin entered. Please enter correct pin." });
        }
      } catch (error) {
        console.error("Error verifying pin:", error);
        alert('Error verifying pin. Please try again.');
      }
    } else {
      notification.error({ message:'Please select the required options'});
    }
  };

  const fetchLocations = async (searchLocation = '') => {
    setLoading(true);
    try {
      const res = await getLocation(searchLocation);
      if (res && res.status === 200) {
        const locations = res.data.message.map((item) => item.custom_location) || [];
        setLocations(locations);
      } else {
        console.error('Failed to get location:', res.status);
      }
    } catch (error) {
      console.error('Error fetching location:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  const handleSearch = (value) => {
    fetchLocations(value);
  };

  useEffect(() => {
    if (selectedLocation) {
      GetRestaurantsByLocation();
    }
  }, [selectedLocation]);

  const GetRestaurantsByLocation = async () => {
    const payload = {
      custom_location: selectedLocation,
    };
    try {
      const res = await getrestaurantByLocation(payload);
      if (res.status === 200) {
        setOutlets(res.data.message);
      }
    } catch (error) {
      console.error('Error fetching restaurants:', error);
    }
  };

  const handleStoreSearch = (value) => {
    const filteredOutlets = outlets.filter((outlet) =>
      outlet.cost_center_name.toLowerCase().includes(value.toLowerCase())
    );
    setOutlets(filteredOutlets);
  };

  return (
    <div className='Auth-Form'>
      <div className='form-group'>
        <Select
          value={selectedLocation}
          onChange={handleLocationChange}
          style={{ width: '100%' }}
          placeholder="Select Location *"
          loading={loading}
          showSearch
          onSearch={handleSearch}
          className="custom-select"
        >
          {locations.map((location) => (
            <Option key={location} value={location}>
              {location}
            </Option>
          ))}
        </Select>
      </div>
      <div className='form-group'>
        <Select
          value={selectedStore ? selectedStore.cost_center_name : undefined}
          onChange={handleStoreChange}
          style={{ width: '100%' }}
          placeholder="Select Store *"
          disabled={!selectedLocation}
          showSearch
          onSearch={handleStoreSearch}
          className="custom-select"
        >
          {outlets.map((outlet) => (
            <Option key={outlet.cost_center_name} value={outlet.cost_center_name}>
              {outlet.cost_center_name}
            </Option>
          ))}
        </Select>
      </div>
      <div className='form-group'>
        <Select
          value={selectedRoute || undefined}  // Use undefined if no value is selected
          onChange={handleRouteChange}
          style={{ width: '100%' }}
          placeholder="Select Platform *"
          className="custom-select"
        >
          <Option value="/cds">CDS</Option>
          <Option value="/kds">KDS</Option>
        </Select>
      </div>
      <div className='form-group'>
        <Input.Password
          value={pin}
          onChange={handlePinChange}
          placeholder="Enter Pin *"
          className="custom-input"
        />
      </div>
      <div className='Auth-button'>
        <Button type="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default App;
