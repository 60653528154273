import React, { useState } from "react";
import { Card, Button, Modal } from "antd"; 
import { completedOrder } from "../module/kichtenCDS/kitchencds";

const OrderCard = ({
  order,
  onComplete,
  heading,
  items,
  apiToken,
  finishClicked,
  onFinishClicked,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [orderRequestModalVisible, setOrderRequestModalVisible] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  // Flatten the items list, including child items
  const getFlattenedItems = (items) => {
    return items.reduce((acc, item) => {
      // Add parent item
      acc.push(item);

      // Add child items
      if (item.child_items && item.child_items.length > 0) {
        acc = acc.concat(item.child_items.map(childItem => ({
          ...childItem,
          parent_item_name: item.item_name
        })));
      }

      return acc;
    }, []);
  };

  const flattenedItems = getFlattenedItems(items);
  const itemsToShow = expanded ? flattenedItems.length : 3;

  const finishOrders = async () => {
    const payload = {
      order_status: {
        name: order.order_id,
        status: "Completed",
      },
    };
    const res = await completedOrder(payload, apiToken);
    if (res.status === 200) {
      console.log(res, "Completed");
      const updatedItems = flattenedItems.filter((item) => item !== selectedItem);
      onComplete(updatedItems);
      // onComplete();
    } else {
      console.error("Failed to complete order:", res);
    }
  };


  const handleOrderComplete = () => {
    if (!finishClicked) {
      onFinishClicked();
    } else {
      finishOrders();
    }
  };

  const handleOrderRequestClick = (item) => {
    setSelectedItem(item);
    setOrderRequestModalVisible(true);
  };

  const handleCloseOrderRequestModal = () => {
    setSelectedItem(null);
    setOrderRequestModalVisible(false);
  };

  const formatTime = (time) => {
    const date = new Date(time);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };
  const formatQuantity = (qty) => {
    return qty.toString().padStart(2, "0");
  };

  return (
    <Card title={heading} className={heading}>
      <div className="order-id">
        #{order.order_id} - {order.source}
      </div>
      <div className="orderTime">
        Order time: {formatTime(order.creation1)}
        <span>Estimate: {order.estimated_time} mins</span>
      </div>
      <ul className="Items-list">
        {flattenedItems.slice(0, itemsToShow).map((item, index) => (
          <li key={index} className={item.custom_is_combo_item ? "combo-item" : item.custom_is_attribute_item ? "attribute-item" : ""}>
            <p>
              <span>{item.item_name}</span> <span className="item-qty">{formatQuantity(item.qty)}</span>
            </p>
            {item.orderRequest && (
              <span
                onClick={() => handleOrderRequestClick(item)}
                className="order-request-kds"
              >
                Order Request
              </span>
            )}
          </li>
        ))}
       {order.custom_order_request && (
          <li
            key="order-request"
            className={`order-request-kds ${expanded ? "expanded" : "collapsed"}`}
          >
            Order Request: {expanded ? order.custom_order_request : `${order.custom_order_request.substring(0, 50)}...`}
          </li>
        )}
        {flattenedItems.length > 3 && (
          <span onClick={toggleExpanded} className="show-more">
            {expanded ? "Show less" : "Show more items"}
          </span>
        )}
      </ul>
      {order.status !== "Completed" && (
        <Button
          type="primary"
          className={`startBtn ${finishClicked ? "finishedBtn" : ""}`}
          onClick={handleOrderComplete}
        >
          {finishClicked ? "Finish" : "Start"}
        </Button>
      )}

      <Modal
        title={`Order Request for ${
          selectedItem ? selectedItem.item_name : ""
        }`}
        visible={orderRequestModalVisible}
        onCancel={handleCloseOrderRequestModal}
        footer={[
          <Button key="close" onClick={handleCloseOrderRequestModal}>
            Close
          </Button>,
        ]}
      >
        {selectedItem && <p>{selectedItem.orderRequest}</p>}
      </Modal>
    </Card>
  );
};

export default OrderCard;
