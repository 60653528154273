import React, { useEffect, useState, useRef } from "react";
import HeaderComponent from "../components/HeaderKDS";
import { Layout } from "antd";
import { getApitoken, getKitchenkds } from "../module/kichtenCDS/kitchencds";
import io from "socket.io-client";

const { Content } = Layout;

function Cds() {
  const socket = useRef();

  const [openOrders, setOpenOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [apiToken, setApiToken] = useState("");

  const costCenter = localStorage.getItem("kdscdsCostCenter");

  const isValidOrder = (order) => {
    return order && typeof order.order_id === "string" && Array.isArray(order.items);
  };

  const filterOrdersByCostCenter = (orders) => {
    return orders.filter(order => isValidOrder(order) && order.cost_center === costCenter);
  };

  const getAPIToken = async () => {
    try {
      const res = await getApitoken();
      if (res.status === 200) {
        const token = res.data.message.data.api_token;
        setApiToken(token);

        const openResponse = await getKitchenkds("Open", token);
        const completedResponse = await getKitchenkds("Completed", token);

        if (openResponse.status === 200) {
          setOpenOrders(filterOrdersByCostCenter(openResponse.data.message));
        }

        if (completedResponse.status === 200) {
          setCompletedOrders(filterOrdersByCostCenter(completedResponse.data.message));
        }
      } else {
        console.error("Error in getting the API Token");
      }
    } catch (error) {
      console.error("Error fetching API token:", error);
    }
  };

  useEffect(() => {
    getAPIToken();
  }, []);

  useEffect(() => {
    socket.current = io("https://kleonode.kleopos.com");
    socket.current.on("connect", () => {
      console.log("connected to server");
    });

    socket.current.on("message", (data) => {
      console.log("received data:", data);

      // Filter incoming data
      const orders = filterOrdersByCostCenter(data.data || []);
      console.log("filtered orders:", orders);

      setOpenOrders((prevOpenOrders) => {
        // Remove orders that are marked as completed
        const updatedOpenOrders = prevOpenOrders.filter(
          (order) =>
            !orders.some((newOrder) => newOrder.order_id === order.order_id && newOrder.status === "Completed")
        );

        // Add new open orders
        const newOpenOrders = orders.filter((order) => order.status === "Open");

        newOpenOrders.forEach((newOrder) => {
          const existingOrderIndex = updatedOpenOrders.findIndex((order) => order.order_id === newOrder.order_id);
          if (existingOrderIndex !== -1) {
            updatedOpenOrders[existingOrderIndex] = newOrder;
          } else {
            updatedOpenOrders.push(newOrder);
          }
        });

        return updatedOpenOrders;
      });

      setCompletedOrders((prevCompletedOrders) => {
        const newCompletedOrders = orders.filter((order) => order.status === "Completed");
        const updatedCompletedOrders = [...prevCompletedOrders];

        newCompletedOrders.forEach((newOrder) => {
          const existingOrderIndex = updatedCompletedOrders.findIndex((order) => order.order_id === newOrder.order_id);
          if (existingOrderIndex !== -1) {
            updatedCompletedOrders[existingOrderIndex] = newOrder;
          } else {
            updatedCompletedOrders.push(newOrder);
          }
        });

        return updatedCompletedOrders;
      });
    });

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, []);

  const renderOrders = (orders) => {
    const ordersPerLine = 7;
    const orderRows = [];
    for (let i = 0; i < orders.length; i += ordersPerLine) {
      orderRows.push(
        <div className="order-row" key={i}>
          {orders.slice(i, i + ordersPerLine).map((order) => (
            <div key={order.order_id} className="order-box">
              {order.order_id}
            </div>
          ))}
        </div>
      );
    }
    return orderRows;
  };

  return (
    <>
      <HeaderComponent welcomeMessage="Good Morning, thank you for choosing Kleo " />
      <Content>
        <div className="main-container">
          <div className="inner-container">
            <div className="sub-container">
              <h2>
                <span>Ready</span>
              </h2>
              {renderOrders(completedOrders)}
            </div>
            <div className="sub-container in-kitchen">
              <h2>
                <span>In The Kitchen</span>
              </h2>
              {renderOrders(openOrders)}
            </div>
          </div>
        </div>
      </Content>
    </>
  );
}

export default Cds;
