import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import "../index.css";
import App from '../App';
import Cds from '../screens/Cds';
import OrderManagement from '../screens/OrderManagement';
import { ThemeProvider } from "../components/ThemeContext"; 



const Routing = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <Router>
      <ThemeProvider> 
        <Routes>
          <Route path="/" element={<App setIsAuthenticated={setIsAuthenticated} />} />
          {/* <Route path="/Auth" element={<AccessControl setIsAuthenticated={setIsAuthenticated} />} /> */}
          <Route 
            path="/cds" 
            element={
              isAuthenticated ? <Cds /> : <Navigate to="/" />
            } 
          />
          <Route 
            path="/kds" 
            element={
              isAuthenticated ? <OrderManagement /> : <Navigate to="/" />
            } 
          />
        </Routes>
      </ThemeProvider>
    </Router>
  );
};

export default Routing;
