import APIs from "../../api/index";
import axiosInstance from "../../translator";

export const getApitoken = async () => {
  try {
    const res = axiosInstance.get(APIs.token);
    return res;
  } catch (error) {
    console.log("Error while getting the token", error);
    return error.response;
  }
};

export const getKitchenkds = async (status, apiToken) => {
  console.log(apiToken);
  try {
    const res = await axiosInstance.get(`${APIs.kitchenKds}?status=${status}`, {
      headers: {
        Authorization: `${apiToken}`,
      },
    });
    return res;
  } catch (error) {
    console.log("Error while getting the kitchenKds", error);
    return error.response;
  }
};

export const completedOrder = async (payload, apiToken) => {
  try {
    const res = await axiosInstance.post(APIs.cds, payload, {
      headers: {
        Authorization: `${apiToken}`,
      },
    });
    return res;
  } catch (error) {
    console.log("Error completing the order", error);
    return error.response;
  }
};


export const verifypin = async (pin, costCenter) => {
  try {
    const res = await axiosInstance.post(`${APIs.verfiyPin}?custom_pin=${pin}&cost_center=${costCenter}`);
    return res;
  } catch (error) {
    console.log("Error completing the order", error);
    return error.response;
  }
};
