import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import logo from "../assets/images/logo.svg";
import welcomeLogo from "../assets/images/welcomeLogo.svg";
import GoogleFontLoader from "react-google-font-loader";
import { getApitoken } from "../module/landingScreen";

const { Header } = Layout;

function HeaderComponent({ welcomeMessage, colorFont }) {
  const [apiToken, setApiToken] = useState(false);

  const getAPIToken = async () => {
    try {
      const res = await getApitoken();
      if (res.status === 200) {
        const apiToken = res.data.message.data;
        setApiToken(apiToken);
      } else {
        console.error("Failed to fetch API token:", res.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getAPIToken();
  }, []);

  return (
    <Header className="header">
      <GoogleFontLoader
        fonts={[
          { font: "ABeeZee", weights: [400, 700] }, // ABeeZee Google Font
        ]}
      />
      <a href="/" className="logo">
        <img src={`${apiToken?.web_logo_image}`} alt="Logo" />
      </a> 
      {/* <p className="welcomeText">
        <img
          src={welcomeLogo}
          alt="Welcome"
          style={{ height: "50px", marginRight: "10px" }}
        />        
        {welcomeMessage}
        <span> {colorFont}</span>
      </p> */}
    </Header>
  );
}

export default HeaderComponent;
